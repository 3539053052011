

































































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default defineComponent({
  name: 'uOttawa1321Lab5MechanismIntro',
  components: {STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        explanation: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      mechanism: [] as File[],
      rules: [(v: string) => v?.length <= 1500 || 'Max 1500 characters'],
      questions: [
        {
          en: 'a) Please provide a brief introduction for the experiment in the field below.',
          fr: "a) Veuillez fournir une brève introduction pour l'expérience dans le champ ci-dessous.",
        },
        {
          en: 'b) Please upload a picture of your reaction mechanism.  Remember to write the mechanism with the chemicals you used in the actual experiment.',
          fr: "b) Veuillez télécharger une photo de votre mécanisme de réaction. N'oubliez pas d'écrire le mécanisme avec les produits chimiques que vous avez utilisés lors de l'expérience réelle.",
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
  computed: {
    attachments(): File[] {
      return [...this.mechanism];
    },
  },
});
